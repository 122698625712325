import * as React from "react";

const WordpressIcon = (props) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="30"
		height="31"
		fill="none"
		viewBox="0 0 30 31"
	>
		<path
			fill="#494949"
			fillRule="evenodd"
			d="M15.363.002C6.994-.147.003 6.643 0 14.878c-.002 8.47 6.668 15.23 15.023 15.217C23.3 30.082 30 23.349 30 15.044 30 6.874 23.33.144 15.363.002m-.36 29.337C7.09 29.341.727 22.925.727 14.945.726 7.17 7.19.734 14.998.73c7.947-.003 14.278 6.454 14.276 14.557-.003 7.65-6.502 14.05-14.27 14.051m7.624-18.544c-.311-.512-.693-1.008-.868-1.566-.2-.635-.366-1.358-.26-1.994.18-1.08 1.007-1.616 2.128-1.718C17.495-.174 7.86 1.863 4.31 8.047c1.366-.064 2.671-.147 3.977-.164.247-.004.656.205.714.399.13.432-.233.583-.62.613l-1.286.098 4.667 13.903c.112-.183.158-.235.179-.295.863-2.58 1.73-5.157 2.572-7.744.077-.236.038-.549-.046-.791-.445-1.285-.978-2.542-1.368-3.842-.267-.89-.621-1.466-1.673-1.363-.18.018-.398-.368-.6-.567.244-.145.485-.411.73-.415q3.213-.05 6.428.002c.245.004.486.278.73.426-.213.194-.397.486-.645.56-.399.12-.841.098-1.318.14l4.672 13.9c.708-2.35 1.367-4.51 2.007-6.674.572-1.932.231-3.736-.802-5.438M11.444 27.111c-.03.083-.03.175-.053.314 2.643.733 5.234.68 7.887-.214l-4.052-11.099zm-2.042-.502L3.297 9.86c-2.755 5.38-.66 13.589 6.105 16.748M26.416 9.297c-.084.935-.025 1.977-.294 2.925-.632 2.235-1.405 4.431-2.149 6.634-.808 2.393-1.65 4.776-2.477 7.164 3.887-2.304 6.022-5.704 6.362-10.173.194-2.543-.684-6.065-1.442-6.55"
			clipRule="evenodd"
		></path>
	</svg>
);

export default WordpressIcon;
