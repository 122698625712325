import MagentoIcon from "../assets/SvgIconComponents/MagentoIcon";
import LaravelIcon from "../assets/SvgIconComponents/LaravelIcon";
import WordpressIcon from "../assets/SvgIconComponents/WordpressIcon";

const INFO = {
	main: {
		title: "Sathya Packirisamy",
		name: "Sathya Prakash Packirisamy",
		email: "satyapackirisamy@gmail.com",
		logo: "../logo.png",
	},

	socials: {
		twitter: "https://x.com/WorldofSatya",
		github: "https://github.com/satyapackirisamy",
		linkedin: "https://www.linkedin.com/in/satya-packirisamy/",
		instagram: "https://www.instagram.com/migraine.vibes",
		stackoverflow: "https://stackoverflow.com/users/6908206/satya",
		facebook: "https://facebook.com/",
	},

	homepage: {
		title: `\n Entrepreneur | Passionate Coder | Committed to Continuous Learning and Innovation`,
		description:
			"Empowering businesses with cutting-edge software solutions, I blend entrepreneurial insight with a passion for coding. As a backend developer specializing in Node.js and PHP (Laravel, WordPress, Magento), I thrive on solving complex problems and creating high-quality, industry-standard code. Committed to continuous learning, I’m always seeking new challenges to grow as a developer.",
	},

	about: {
		title: "I’m Sathya Prakash Packirisamy. I live in Chennai, India",
		description:
			"Welcome! I’m a passionate backend developer dedicated to empowering businesses with cutting-edge software solutions. With expertise in Node.js and PHP frameworks such as Laravel, WordPress, and Magento, I thrive on solving complex problems and creating high-quality, industry-standard code. My journey is driven by a blend of entrepreneurial insight and a deep-seated passion for coding. I understand that every line of code can have a significant impact on a business, which is why I commit to excellence in every project I undertake. Whether it's developing robust APIs or building scalable applications, I focus on delivering solutions that drive growth and efficiency.",
	},

	articles: {
		title: "I'm passionate about pushing the boundaries of what's possible and inspiring the next generation of innovators.",
		description: "Please find my articles in the following link",
		hashnode: "https://sathyaprakash.hashnode.dev/",
	},

	projects: [
		{
			title: "Orthoberry",
			description:
				"A patient management system and Payment collection management application based on SaaS",
			logo: "https://cdn.jsdelivr.net/npm/programming-languages-logos/src/javascript/javascript.png",
			linkText: "View Project",
			link: "https://app.orthoberry.com",
		},

		{
			title: "Ailaysa",
			description:
				"Multilingual AI based content creation SaaS platform for all kind of content developers",
			logo: "https://cdn.jsdelivr.net/npm/programming-languages-logos/src/python/python.png",
			linkText: "View Project",
			link: "https://ailaysa.com",
		},

		{
			title: "Helvetica Lifestyle",
			description:
				"An e-commerce website made of PHP's framework Magento2 for Luxury apparels for men and women.",
			logo: <MagentoIcon />,
			linkText: "View Project",
			link: "https://helveticalifestyle.com/",
		},

		{
			title: "Caapid Simplified",
			description:
				"Admissions counseling platform for the International Dentists who appies ADEA Centralized Application for Advanced Placement",
			logo: <LaravelIcon />,
			linkText: "View Project",
			link: "https://caapidsimplified.com/",
		},

		{
			title: "Aazhi Books",
			description:
				"An eCommerce website made of PHP CMS Wordpress for selling Multi-lingual books",
			logo: <WordpressIcon />,
			linkText: "View Project",
			link: "https://www.aazhibooks.com/",
		},
	],
};

export default INFO;
