import React from "react";
import { faBriefcase } from "@fortawesome/free-solid-svg-icons";

import Card from "../common/card";

import "./styles/works.css";

const Works = () => {
	return (
		<div className="works">
			<Card
				icon={faBriefcase}
				title="Work"
				body={
					<div className="works-body">
						<div className="work">
							<img
								src="https://cdn.jsdelivr.net/npm/material-letter-icons@1.0.8/dist/png/O.png"
								alt="facebook"
								className="work-image"
							/>
							<div className="work-title">Orthoberry (Startup)</div>
							<div className="work-subtitle">
								Technical Head
							</div>
							<div className="work-duration">2023 - Present</div>
						</div>

						<div className="work">
							<img
								src="https://cdn.jsdelivr.net/npm/material-letter-icons@1.0.8/dist/png/P.png"
								alt="twitter"
								className="work-image"
							/>
							<div className="work-title">PicnicHealth</div>
							<div className="work-subtitle">
								Growth Web Developer
							</div>
							<div className="work-duration">2023 - Present</div>
						</div>

						<div className="work">
							<img
								src="https://cdn.jsdelivr.net/npm/material-letter-icons@1.0.8/dist/png/L.png"
								alt="twitter"
								className="work-image"
							/>
							<div className="work-title">Ailaysa/Langscape</div>
							<div className="work-subtitle">
								Senior Product Manager
							</div>
							<div className="work-duration">2022 - 2023</div>
						</div>

						<div className="work">
							<img
								src="https://cdn.jsdelivr.net/npm/material-letter-icons@1.0.8/dist/png/A.png"
								alt="twitter"
								className="work-image"
							/>
							<div className="work-title">Alchem Digital</div>
							<div className="work-subtitle">
								Co-founder / Technical Head
							</div>
							<div className="work-duration">2020 - Present</div>
						</div>

						<div className="work">
							<img
								src="https://cdn.jsdelivr.net/npm/material-letter-icons@1.0.8/dist/png/B.png"
								alt="twitter"
								className="work-image"
							/>
							<div className="work-title">Bleap</div>
							<div className="work-subtitle">
								Senior web developer
							</div>
							<div className="work-duration">2017 - 2020</div>
						</div>

						<div className="work">
							<img
								src="https://cdn.jsdelivr.net/npm/material-letter-icons@1.0.8/dist/png/T.png"
								alt="twitter"
								className="work-image"
							/>
							<div className="work-title">That1Card</div>
							<div className="work-subtitle">
								Web developer
							</div>
							<div className="work-duration">2015 - 2017</div>
						</div>
					</div>
				}
			/>
		</div>
	);
};

export default Works;
